<template>
  <div>
    <vue-headful title="Document Descriptions"></vue-headful>
    <div class="top25">
      <div class="row">
        <div class="col-md-12">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">Category</th>
              <th scope="col">Description</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Amendment</td>
              <td>Name changes, member manager information, address changes, business purpose and all other amending filings, changes to including stock information.</td>
            </tr>
            <tr>
              <td>Annual Report</td>
              <td>Filed documents for annual reports, and filed compliance documents to keep your entity active at the state.</td>
            </tr>
            <tr>
              <td>Business License Report</td>
              <td>Business License Reports and other business license documents. Should not contain paperwork from the state or secretary of state.</td>
            </tr>
            <tr>
              <td>Beneficial Ownership Filing Transcript</td>
              <td>This is the Beneficial Ownership Filing Transcript provided from FinCEN as evidence of a successful submission.</td>
            </tr>
            <tr>
              <td>Certified</td>
              <td>Copies of certified document, must contain a state seal or an officer signature from the state.</td>
            </tr>
            <tr>
              <td>DBA</td>
              <td>Fictitious name, DBA, and assumed name documents, may be provided by the customer.</td>
            </tr>
            <tr>
              <td>EIN</td>
              <td>SS4, 88-32, 2553, Employment Identification number, this document is issued by the IRS.</td>
            </tr>
            <tr>
              <td>Foreign Qualification</td>
              <td>Name changes, member manager information, address changes, business purpose and all other amending filings, changes to including stock information.</td>
            </tr>
            <tr>
              <td>Formation</td>
              <td>This is domestic formation documentation including articles of incorporation, articles of organization, and certificate of formation. Issued by the state and/or SoS.</td>
            </tr>
            <tr>
              <td>Good Standing</td>
              <td>Good Standing, Certificate of Status, Texas Certificate of fact, certificate of existence, certificate of good standing, a document indicating the company is in compliance at the state.</td>
            </tr>
            <tr>
              <td>Insurance (BoP)</td>
              <td>Any documents related to business owner policy insurance. Likely provided by a partner, but may also be provided by a customer.</td>
            </tr>
            <tr>
              <td>Insurance (Health)</td>
              <td>Any documents related to health insurance. Likely provided by a partner, but may also be provided by a customer in some cases</td>
            </tr>
            <tr>
              <td>Operating Agreement/Corporate Bylaws</td>
              <td>Defines business structure, shares, and business operations. Can be generated by our system, a customer, or lawyers.</td>
            </tr>
            <tr>
              <td>Partner - Unspecified</td>
              <td>Documents from partners that do not have a specified category such as loans, domains, and other areas we expand into.</td>
            </tr>
            <tr>
              <td>Other</td>
              <td>names reservation certificates, consent letters, and all other uncategorized documents.</td>
            </tr>
            <tr>
              <td>Publication</td>
              <td>Proof of newspaper or journal publication. For New York, Arizona, Kentucky, Nevada, and other states with publication requirements.</td>
            </tr>
            <tr>
              <td>Reinstatement</td>
              <td>Filed documents for reinstatements.</td>
            </tr>
            <tr>
              <td>Registered Agent Filing</td>
              <td>Evidence for change of Registered Agent or resignation.</td>
            </tr>
            <tr>
              <td>SOO</td>
              <td>Statement of the organizer is a resignation of organizer and appoints the members and managers.</td>
            </tr>
            <tr>
              <td>State Resource</td>
              <td>Anything from the state that contains important information about the business entity after formation including CID, PIN, Access codes, CA SOS info letter, state updates, and webfile numbers. Not for formation evidence.</td>
            </tr>
            <tr>
              <td>Taxes</td>
              <td>Any documents from the department of revenue, and tax related documents from partners.</td>
            </tr>
            <tr>
              <td>Compliance Notification</td>
              <td>Compliance notices including state, federal, and local notices. This does not include filed annual reports.</td>
            </tr>
            <tr>
              <td>SoP</td>
              <td>Service of Process, Summons, Complains, Garnishments, Subpoena, Notice to appear in count, Hearing Notices, and all other legal/court related documents.</td>
            </tr>
            <tr>
              <td>Mail</td>
              <td>Not for filed items. All items received in the mail that do not have a specific category.</td>
            </tr>
            <tr>
              <td>Appoint of Officer and Directors</td>
              <td>Resigns the incorporator and appoints the officers and directors.</td>
            </tr>
            <tr>
              <td>Cancel/Dissolution/Termination</td>
              <td>All documents relating to the formal closure of an entity.</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DocumentDescriptions'
};
</script>

<style lang="scss">
  h1 {
    padding: 10px 0 0 10px;
    font-size: 20pt;
    display: inline;
  }
  .nav-link {
    display: inline;
  }
  .top25{
    margin-top: 25px;
  }
  .top10{
    margin-top: 10px;
  }
  .inline{
    display: inline;
  }
  .left5{
    margin-left: 5px;
  }
  .account-list-header-center {
    text-align: center;
  }
</style>
